import React from 'react'
import SecondaryLayout from '../layouts/SecondaryLayout'
import Column from '../layouts/Column'
import '../styles/normalize.scss'
import '../styles/variables.scss'
import '../styles/fonts.scss'
import '../styles/global.scss'
import '../styles/link.scss'
import { graphql } from 'gatsby'
import PagePadding from '../components/PagePadding/PagePadding'
import ReactHtmlParser from 'react-html-parser'
import TripDayPlanning from '../components/TripDayPlanning/TripDayPlanning'
import TextWithButtons from '../components/TextWithButtons/TextWithButtons'
import HeroBigAsset from '../components/HeroBigAsset/HeroBigAsset'

import FullWidthImageCarousel from '../components/FullWidthImageCarousel/FullWidthImageCarousel'
import ReviewCarousel from '../components/ReviewCarousel/ReviewCarousel'
import TextInColumns from '../components/TextInColumns/TextInColumns'

export default function OtherActivities(props) {
	const wordpressAcfOverigeactiviteiten =
		props.data.wordpressAcfOverigeactiviteiten
	const { title, description } = wordpressAcfOverigeactiviteiten.acf

	const allWordpressAcfReview = props.data.allWordpressAcfReview

	const reviewCards = allWordpressAcfReview.edges.map((review) => {
		return {
			quote: review.node.acf.review,
			cite: {
				age: review.node.acf.age,
				name: review.node.acf.name,
				level: review.node.acf.level,
			},
		}
	})

	const timeline =
		wordpressAcfOverigeactiviteiten.acf.reis_tijdlijn &&
		wordpressAcfOverigeactiviteiten.acf.reis_tijdlijn.map((day) => {
			if (!day.reis_dag.de_dag.length) {
				return []
			}
			let formattedDays = {}

			day.reis_dag.de_dag.forEach((dag, i) => {
				const data = {
					['image' + i]: {
						src: dag.foto.source_url,
						alt: dag.foto.alt_text,
					},
					['article' + i]: {
						title: dag.titel,
						text: dag.beschrijving,
					},
				}

				formattedDays = { ...formattedDays, ...data }
			})

			return {
				day: {
					name: `Optie ${day.reis_dag.dagnummer}`,
					number: day.reis_dag.dagnummer,
					title: day.reis_dag.dag_titel,
				},
				content: formattedDays,
			}
		})
	const information = [
		{
			title: wordpressAcfOverigeactiviteiten.acf.about_course.title_1,
			content: ReactHtmlParser(
				wordpressAcfOverigeactiviteiten.acf.about_course.paragraph_1,
			),
		},
		{
			title: wordpressAcfOverigeactiviteiten.acf.about_course.title_2,
			content: ReactHtmlParser(
				wordpressAcfOverigeactiviteiten.acf.about_course.paragraph_2,
			),
		},
		{
			title: wordpressAcfOverigeactiviteiten.acf.about_course.title_3,
			content: ReactHtmlParser(
				wordpressAcfOverigeactiviteiten.acf.about_course.paragraph_3,
			),
		},
	]

	return (
		<>
			<SecondaryLayout>
				{wordpressAcfOverigeactiviteiten.acf.pictures &&
				wordpressAcfOverigeactiviteiten.acf.pictures[0] &&
				wordpressAcfOverigeactiviteiten.acf.pictures[0].source_url ? (
					<HeroBigAsset
						image={{
							small:
								wordpressAcfOverigeactiviteiten.acf.pictures &&
								wordpressAcfOverigeactiviteiten.acf
									.pictures[0] &&
								wordpressAcfOverigeactiviteiten.acf.pictures[0]
									.source_url
									? wordpressAcfOverigeactiviteiten.acf
											.pictures[0].source_url
									: '/images/inbalans-image-10.jpg',
							large:
								wordpressAcfOverigeactiviteiten.acf.pictures &&
								wordpressAcfOverigeactiviteiten.acf
									.pictures[0] &&
								wordpressAcfOverigeactiviteiten.acf.pictures[0]
									.source_url
									? wordpressAcfOverigeactiviteiten.acf
											.pictures[0].source_url
									: '/images/inbalans-image-10.jpg',
							alt:
								wordpressAcfOverigeactiviteiten.acf.pictures &&
								wordpressAcfOverigeactiviteiten.acf
									.pictures[0] &&
								wordpressAcfOverigeactiviteiten.acf.pictures[0]
									.alt_text &&
								wordpressAcfOverigeactiviteiten.acf.pictures[0]
									.alt_text.length > 2
									? wordpressAcfOverigeactiviteiten.acf
											.pictures[0].alt_text
									: 'Cursus bij In Balans Alkmaar',
						}}
						course={true}
					/>
				) : null}

				<PagePadding>
					<Column>
						<TextWithButtons
							localProps={[
								{
									title: title,
									text: description,
									buttonPrimary: {
										text: 'mail ons!',
										to: `mailto:info@inbalansalkmaar.nl?subject=${title}`,
									},
								},
							]}
						/>
						<PagePadding>
							{typeof information !== 'undefined' ? (
								<TextInColumns content={information} />
							) : null}
						</PagePadding>
					</Column>
				</PagePadding>

				{wordpressAcfOverigeactiviteiten.acf.pictures &&
				wordpressAcfOverigeactiviteiten.acf.pictures.length > 2 ? (
					<div>
						<FullWidthImageCarousel
							className={'full-width__image-carousel'}
							images={
								wordpressAcfOverigeactiviteiten.acf.pictures
							}
						/>
					</div>
				) : null}
				<PagePadding>
					{reviewCards.length >= 3 && (
						<ReviewCarousel
							className={'review__carousel'}
							cards={reviewCards}
						/>
					)}

					{timeline && (
						<TripDayPlanning cards={timeline} variant={'Optie'} />
					)}
				</PagePadding>
			</SecondaryLayout>
		</>
	)
}

export const query = graphql`
	query($wordpress_id: Int!) {
		wordpressAcfOverigeactiviteiten(wordpress_id: { eq: $wordpress_id }) {
			acf {
				category
				description
				subject
				title
				pictures {
					alt_text
					source_url
					template
					title
					type
					wordpress_id
					status
				}
				about_course {
					paragraph_1
					paragraph_2
					paragraph_3
					title_1
					title_2
					title_3
				}
				reis_tijdlijn {
					reis_dag {
						dag_titel
						dagnummer
						de_dag {
							beschrijving
							titel
							foto {
								alt_text
								source_url
							}
						}
					}
				}
			}
		}
		allWordpressAcfReview(
			filter: { acf: { type_review: { eq: "Activiteiten" } } }
		) {
			edges {
				node {
					acf {
						age
						level
						name
						review
					}
				}
			}
		}
	}
`
